<template>
	<div class="h100">
		<router-view />
	</div>
</template>

<script>
export default{
	name:'parent',
}
</script>
